import { mapActions } from 'vuex';

export default {
  data() {
    return {
      previosPageParams: null,
      isLoadingSaveButton: false,
    };
  },

  computed: {
    isEqual() {
      return this.lodash.isEqual(this.initialForm, this.form);
    },
  },

  created() {
    this.previosPageParams = this.$route.params;
    this.form = this.lodash.cloneDeep(this.initialForm);
  },

  destroyed() {
    this.clearState();
  },

  methods: {
    ...mapActions({
      clearState: 'editableList/clearState',
    }),

    onSave(route) {
      const query = {};

      this.isLoadingSaveButton = true;

      if (this.previosPageParams.previosRouteName === route) {
        query.table = JSON.stringify(
          this.previosPageParams.previosRouteQuerySort
        );
        query.filter = JSON.stringify(
          this.previosPageParams.previosRouteQueryFilter
        );
      }

      this.createItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed || result.isSucceed === undefined) {
            this.$router.push({ name: route, query });
          } else throw new Error();
        })
        .catch(() => {
          this.$notify({
            header: 'Ошибка',
            text: 'При сохраненнии данных произошла ошибка',
            type: 'error',
            timer: 5000,
          });
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },

    onCancel(route) {
      const query = {};

      if (this.previosPageParams.previosRouteName === route) {
        query.table = JSON.stringify(
          this.previosPageParams.previosRouteQuerySort
        );
        query.filter = JSON.stringify(
          this.previosPageParams.previosRouteQueryFilter
        );
      }

      if (this.isEqual) {
        this.$router.push({ name: route, query });
        return;
      }

      this.$root
        .$confirmModal({
          message: 'Отменить создание?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.$router.push({ name: route, query });
          }
        });
    },
  },
};
